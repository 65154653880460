import {html, render} from 'lit-html';
import {repeat} from 'lit/directives/repeat.js';
import {asyncReplace} from 'lit/directives/async-replace.js';
import {guard} from 'lit-html/directives/guard.js';
import {ref, createRef} from 'lit/directives/ref.js';
import { Terminal } from '@xterm/xterm';
import { FitAddon } from '@xterm/addon-fit';

const ws = new WebSocket(location.origin.replace(/^http/, 'ws') + '/app');
ws.addEventListener('error', console.error);
let openWs;
openWs = new Promise(resolve => {
  if (ws.readyState === WebSocket.CONNECTING) {
    ws.addEventListener('open', async () => {
      resolve(openWs = ws);
    });
  } else {
    resolve(openWs = ws);
  }
});

let terminalDataInterrupt = () => {};
const terminalDataBuffer = [];
function yieldTerminalData(data) {
  terminalDataBuffer.push(data);
  terminalDataInterrupt();
}
const terminalData = (async function*() {
  for (;;) {
    while (terminalDataBuffer.length === 0) {
      await new Promise(resolve => terminalDataInterrupt = resolve);
    }
    const buffer = terminalDataBuffer.splice(0);
    for (const data of buffer) {
      yield data;
    }
  }
})();

function base64ToUint8Array(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

ws.addEventListener('message', async function onmessage(e) {
  const json = JSON.parse(e.data);
  switch (json.type) {
    case 'terminalWriteString': {
      const {string} = json;
      yieldTerminalData({string});
      break;
    }
    case 'terminalWriteBase64': {
      const {base64} = json;
      yieldTerminalData({data: base64ToUint8Array(base64)});
      break;
    }
  }
});
ws.addEventListener('close', async function onmessage(e) {
  if (process.env.NODE_ENV !== 'production') {
    setTimeout(() => {
      location.reload(true);
    }, 5e3);
  }
});

if (process.env.NODE_ENV !== 'production' && false) {
  setTimeout(() => {
    location.reload(true);
  }, 60e3);
}

function onload() {
  const term = new Terminal();
  const fitAddon = new FitAddon();
  term.loadAddon(fitAddon);
  term.open(document.body);
  term.onTitleChange(title => {
    document.title = `Terminal - ${title}`;
  });
  (async () => {
    for await (const {string, data} of terminalData) {
      if (string != null) {
        term.write(string);
      } else if (data != null) {
        term.write(data);
      }
    }
  })();

  new ResizeObserver(async (entries) => {
    const height = (document.body.parentElement ?? {}).clientHeight ?? window.innerHeight;
    const width = (document.body.parentElement ?? {}).clientWidth ?? window.innerWidth;
    document.body.style.height = Math.max(900, height) + 'px';
    fitAddon.fit();
    (await openWs).send(JSON.stringify({
      type: 'resize',
      rows: term.rows,
      cols: term.cols,
    }));
  }).observe(document.body);
}

export default {
  onload,
};
